import Link from 'components/GatsbyLinkWrapper/index'
import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import { Section } from './style'

const GlobalAccountBusiness = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)

  return (
    <Section className='py-5' role='img' aria-label='Cliente Inter Empresas caminhando pela rua enquanto acessa seu Super App'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            {windowWidth < WIDTH_MD &&
              <div className='d-flex justify-content-center'>
                <ImageWebp
                  arrayNumbers={[ 306, 320, 396, 495 ]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/empresa-mulher/image.webp'
                  altDescription='Cliente Inter Empresas caminhando pela rua enquanto acessa seu Super App'
                />
              </div>}
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mt-3 mb-3'>
              <span className='d-lg-block d-xl-inline'>Global Account Business:</span> a conta internacional para empresas do Inter
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              Aqui sua empresa tem uma conta americana completa, sem taxas e com Account e Routing Number Americanos, para receber e enviar remessas internacionais com muito mais facilidade.
            </p>
            <Link
              title='Investir agora'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/empresas/global-account-business/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Saber mais',
                  section_name: 'Global Account Business: a conta internacional para empresas do Inter',
                  redirect_url: `${window.location.origin}/empresas/global-account-business/`,
                })
              }}
            >
              Saber mais
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GlobalAccountBusiness
