import Link from 'components/GatsbyLinkWrapper/index'
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const BusinessInsurance = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex' role='img' aria-label='Cliente Inter Empresas segurando seu cartão de crédito empresarial'>
      <div className='container d-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6 col-lg-5 offset-lg-7'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-white fw-500 mb-3'>
              Seguros empresariais: seu negócio protegido contra imprevistos
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-white fw-400 mt-md-4'>No Inter Empresas você encontra as melhores opções para proteger seus funcionários, o patrimônio e garantir tranquilidade para a rotina do seu negócio.</p>
            <Link
              title='Saber mais'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3 mt-md-2'
              to='/empresas/seguros/'
              target='_blank'
              onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_10',
                    element_action: 'click button',
                    element_name: 'Saber mais',
                    section_name: 'Seguros empresariais: seu negócio protegido contra imprevistos',
                    redirect_url: `${window.location.origin}/empresas/seguros/`,
                  })
              }}
            >
              Saber mais
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default BusinessInsurance
