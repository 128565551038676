import Link from 'components/GatsbyLinkWrapper/index'
import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const GranitoMachine = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center'>
            <ImageWebp
              arrayNumbers={[ 308, 337, 387, 519 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pro-seu-negocio-dobra4-1440/image.webp'
              altDescription='Maquininha de cartão Inter Pag com cartão Inter Empresas.'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Maquininha de cartão é Inter Pag
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              Aqui o seu negócio garante benefícios exclusivos, como: taxas de antecipação personalizadas, custo zero para API Pix, mais chances de acesso a Capital de Giro e muito mais!
            </p>
            <Link
              title='Saber mais'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3 mt-md-2'
              to='/empresas/maquininha-de-cartao/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Saber mais',
                  section_name: 'Maquininha de Cartão Granito: Um universo de soluções pro seu negócio',
                  redirect_url: `${window.location.origin}/empresas/maquininha-de-cartao/`,
                })
              }}
            >
              Saber mais
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GranitoMachine
