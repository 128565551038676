import Link from 'components/GatsbyLinkWrapper/index'
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const CreditSolutions = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center' role='img' aria-label='Cliente Inter Empresas sorridente enquanto pesquisa sobre as soluções de crédito disponíveis no Super App'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6'>
            <div className='bg-white rounded-4 px-3 px-lg-4 py-4 py-lg-5'>
              <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
                <span className='d-xl-block'>Soluções de Crédito:</span> nós ajudamos sua empresa a crescer!
              </h2>
              <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
                Conte com soluções de crédito seguras, com contratação simplificada e taxas que cabem no seu bolso — tudo para tirar seus objetivos do papel!
              </p>
              <Link
                title='Saber mais'
                className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-3 mt-md-2'
                to='/empresas/emprestimos/emprestimo-corporativo/'
                target='_blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Saber mais',
                    section_name: 'Soluções de Crédito: nós ajudamos sua empresa a crescer!',
                    redirect_url: `${window.location.origin}/empresas/emprestimos/emprestimo-corporativo/`,
                  })
                }}
              >
                Saber mais
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CreditSolutions
